import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Table from "lib/@cms/components/cms/Table";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Divider from "lib/@cms/components/primitive/Divider";

function MortgageOptionsPage() {
  return (
    <Page pathname="mortgage-options">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <div className="tw-container">
              <ContentBox title={data.TextBodyA?.title}>{data.TextBodyA?.description}</ContentBox>
              <Divider className="tw-my-6" />

              {data.Table?.map((table, index) => {
                return (
                  <div key={`Table-table-${index}`}>
                    <Table
                      width="tw-w-2/4"
                      title={table.title}
                      description={table.description}
                      HeadTitles={table.HeadTitles}
                      TableItems={table.TableItems}
                    />
                    <Divider className="tw-my-6" />
                  </div>
                );
              })}
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default MortgageOptionsPage;
